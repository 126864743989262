<!-- 컨텐츠 등록/수정 -->
<template>
  <v-container
    id="contentsRegister"
    fluid
    tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
      <base-material-card
              color="green">
        <template v-slot:heading>
          <h1 class="display-2 font-weight-regular">
            컨텐츠 {{resultType}}
          </h1>
        </template>
        <v-card-text>
          <v-form
                  v-model="vModel.valid"
                  ref="form">
              <v-row class="pl-5 pr-5"
                      align="center">
                  <v-col cols="1" class="text-right grey--text">
                      컨텐츠 종류
                  </v-col>
                  <v-col cols="5" v-if="resultType !== '수정'">
                      <v-select
                              :items="config.category1"
                              v-model="vModel.data.type"
                              placeholder="선택하세요."
                              color="secondary"
                              @change="changeType"
                              :rules="config.typeRules"
                              required />
                  </v-col>
                  <v-col cols="5" v-else>
                      <v-text-field
                              type="text"
                              name="type"
                              color="secondary"
                              v-model="vModel.data.type"
                              disabled/>
                  </v-col>
                  <v-col cols="1" class="text-right grey--text">
                      카테고리
                  </v-col>
                  <v-col cols="5" v-if="resultType !== '수정'">
                      <v-select
                              :items="config.category2"
                              v-model="vModel.data.category"
                              placeholder="선택하세요."
                              color="secondary"
                              :rules="config.categoryRules"
                              :disabled="!vModel.data.type"
                              required />
                  </v-col>
                  <v-col cols="5" v-else>
                      <v-text-field
                              type="text"
                              name="category"
                              color="secondary"
                              v-model="vModel.data.category"
                              disabled/>
                  </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center">
                  <v-col cols="1" class="text-right grey--text">
                      글쓴이
                  </v-col>
                  <v-col cols="5">
                      <v-text-field
                              placeholder="글쓴이"
                              type="text"
                              name="writer"
                              color="secondary"
                              v-model="vModel.data.writer"
                              :rules="config.writerRules"
                              required />
                  </v-col>
                  <template v-if="vModel.data.type !== 'Video'">
                      <v-col cols="1" class="text-right grey--text">
                          썸네일
                      </v-col>
                      <!--썸네일 첨부-->
                      <v-col cols="5" v-if="response.routeName === 'contentsRegister' || response.thumbnailVal">
                          <v-file-input
                                  v-model="thumbnail_url"
                                  name="thumbnail_url"
                                  placeholder="파일 첨부"
                                  prepend-icon="mdi-camera" />
                      </v-col>
                      <!--썸네일 미첨부-->
                      <template v-else>
                          <v-col cols="4">
                              <v-text-field
                                      placeholder="파일 첨부"
                                      prepend-icon="mdi-camera"
                                      type="text"
                                      name="thumbnail_url"
                                      color="secondary"
                                      v-model="vModel.data.thumbnail_url"
                                      disabled/>
                          </v-col>
                          <div @click="thumbnailClose()" style="cursor: pointer;">X</div>
                      </template>
                  </template>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center" v-if="vModel.data.type !== 'Video'">
                  <v-col cols="1" class="text-right grey--text">
                      제목
                  </v-col>
                  <v-col>
                      <v-text-field
                              placeholder="제목"
                              type="text"
                              name="title"
                              color="secondary"
                              v-model="vModel.data.title"
                              :rules="config.titleRules"
                              required/>
                  </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center"
                     v-if="vModel.data.type !== 'Video'">
                  <v-col cols="1" class="text-right grey--text">
                      요약 내용
                  </v-col>
                  <v-col>
                      <v-textarea
                              placeholder="요약 내용"
                              name="sub_title"
                              color="secondary"
                              auto-grow
                              rows="1"
                              row-height="5"
                              no-resize
                              v-model="vModel.data.sub_title" />
                  </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center" v-if="vModel.data.type !== 'Video'">
                  <v-col cols="1" class="text-right grey--text">
                      태그
                  </v-col>
                  <v-col>
                      <v-text-field
                              placeholder="태그"
                              hint="태그를 ,로 구분하여 입력하세요."
                              type="text"
                              name="tags"
                              color="secondary"
                              v-model="vModel.data.tags" />
                  </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center" v-if="vModel.data.type === 'Video'">
                  <v-col cols="1" class="text-right grey--text">
                      URL
                  </v-col>
                  <v-col>
                      <v-text-field
                              label="URL 주소"
                              type="text"
                              name="contents"
                              color="secondary"
                              v-model="vModel.data.contents"
                              :rules="config.urlRules"
                              required/>
                  </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     justify="space-around"
                     v-if="vModel.data.type !== 'Video'">
                  <!--에디터-->
                  <v-col cols="12">
                      <vue-editor id="editor"
                                  v-model="vModel.data.contents"
                                  :editorToolbar="customToolbar"
                                  useCustomImageHandler
                                  @text-change="editorChange"
                                  @image-added="handleImageAdded"></vue-editor>
                  </v-col>
            </v-row>
            <v-row justify="center"
                   class="mt-5">
              <v-btn
                      color="default"
                      @click="beforePage()">
                취소
              </v-btn>
              <v-btn
                      :disabled="!vModel.valid"
                      color="success"
                      @click="submitRegister(resultType)">
                {{resultType}}
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
      v-model="vModel.snackbar"
      :type="vModel.snackbarType"
      v-bind="{
        'top': true,
        'right': true
      }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { VueEditor } from 'vue2-editor'
import { common } from '../../mixins/common.js'
import { editorSet } from '../../mixins/editorSet.js'

export default {
  components: {
    VueEditor
  },
  mixins: [ common, editorSet ],
  created () {
    this.refresh()
  },
  data () {
    return {
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [ false, 1, 2, 3, 4, 5, 6, ] }],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        [{ 'header': 1 }, { 'header': 2 }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image'],
        // ['link', 'image', 'video', 'formula'],
        [{ 'direction': 'rtl' }],
        // ['clean'],
      ],
      response: {
        routeName: this.$route.name,
        category: null,
        category2: {},
        contents: null,
        partner: null,
        register: null,
        edit: null,
        thumbnailVal: true,
        image: [],
      },
      vModel: {
        valid: true,
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
        created: this.$moment(new Date()).format('YYYY-MM-DD'),
        contents: null,
        data: {
          title: null,
          sub_title: null,
          writer: null,
          type: null,
          category: null,
          tags: null,
          contents: null,
          partner: null,
          thumbnail_url: null,
        },
      },
      thumbnail_url: null,
      config: {
        category1: [],
        category2: [],
        titleRules: [ v => !!v || '제목을 입력하세요.' ],
        subTitleRules: [ v => !!v || '요약 내용을 입력하세요.' ],
        writerRules: [ v => !!v || '글쓴이를 입력하세요.' ],
        typeRules: [ v => !!v || '컨텐츠 종류를 선택하세요.' ],
        categoryRules: [ v => !!v || '카테고리를 선택하세요.' ],
        urlRules: [ v => !!v || 'url을 입력하세요.' ],
        tagsRules: [ v => !!v || '태그를 입력하세요.' ],
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      this.getCategories()
      this.getPartners()
    },
    /* 종류 변경시 */
    changeType (val) {
      this.vModel.data.category = null
      this.config.category2 = this.response.category2[val]
    },
    /* 등록된 썸네일 지우기 */
    thumbnailClose () {
      this.vModel.data.thumbnail_url = null
      this.response.thumbnailVal = true
    },
    /* 등록,수정 시 태그 변환 */
    tagChange (val) {
      this.vModel.contents = this.editorTagChange(val)
      this.ContentsValidate(val)
    },
    /* 종류, 카테고리 리스트 가져오기 */
    getCategories () {
      this.xhttp({
        url: '/categories',
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.category = response.data.data.categories
          this.config.category1 = this.response.category.map(function ($value) {
            return { value: $value.alias, text: $value.name }
          })
          this.config.category1 = this.config.category1.filter(function ($value) {
            return $value.value !== 'Toon'
          })
          for (let i in this.response.category) {
            this.response.category2[this.response.category[i].alias] = this.response.category[i].children.map(function ($value) {
              return { value: $value.id, text: $value.name }
            })
          }
          if (this.response.routeName === 'contentsEdit') {
            this.getContents()
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 컨텐츠 정보 가져오기 */
    getContents () {
      this.xhttp({
        url: '/contents/' + this.$route.params.contents_id + '/edit',
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.contents = response.data.data.contents
          this.vModel.data = { ...this.response.contents }
          this.vModel.data.title = this.convertHtml(this.vModel.data.title)
          if (this.vModel.data.sub_title) {
            this.vModel.data.sub_title = this.convertHtml(this.vModel.data.sub_title)
          }
          this.vModel.data.created = this.vModel.data.created.slice(0, 10)
          let typeVal = this.vModel.data.type
          let type = this.config.category1.filter(function ($value) {
            return $value.text === typeVal
          })
          this.vModel.type = type[0].value
          this.config.category2 = this.response.category2[type[0].value]
          let num = this.vModel.data.category
          let result = this.config.category2.filter(function ($value) {
            return $value.value === num
          })
          this.vModel.data.category = result[0].text
          this.vModel.data.thumbnail_url !== 'null' ? this.response.thumbnailVal = false : this.response.thumbnailVal = true
          /* 에디터에 들어갈 내용 태그 변경 */
          let editorContent = this.editorSaveTagChange(this.response.contents.contents)
          this.vModel.data.contents = editorContent
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* cp - 나의 key값 가져오기 */
    getPartners () {
      let send = {
        orderby: 'created',
        direction: 'desc'
      }
      this.xhttp({
        url: '/partners',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.partner = response.data.data.partners[0].key
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 등록, 수정 버튼 클릭시 */
    submitRegister (val) {
      if (this.$refs.form.validate()) {
        if (this.vModel.data.thumbnail_url) {
          let message = '등록 하시겠습니까?'
          if (val === '수정') {
            message = '수정 하시겠습니까?'
          }
          if (confirm(message)) {
            this.tagChange(val)
          }
        } else {
          this.snack('warning', '썸네일을 등록하세요.')
        }
      }
    },
    /* 등록,수정 확인하기 */
    ContentsValidate (val) {
      if (this.vModel.data.type === 'Video') {
        if (val === '등록') {
          this.putContents()
        } else {
          this.contentsEdit()
        }
      } else {
        if (this.vModel.data.contents) {
          if (val === '등록') {
            this.putContents()
          } else {
            this.contentsEdit()
          }
        } else {
          this.snack('warning', '내용을 입력하세요.')
        }
      }
    },
    /* 컨텐츠 등록하기 */
    putContents () {
      let send = this.vModel.data
      send.contents = this.vModel.contents
      this.xhttp({
        url: '/contents/' + this.response.partner + '/create',
        method: 'post',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.register = response.data.data
          this.snack('success', '컨텐츠 등록이 완료되었습니다.')
          this.$router.push({ name: 'contentsList' })
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 컨텐츠 수정하기 */
    contentsEdit () {
      let send = { ...this.vModel.data }
      send.partner = this.response.partner
      send.contents = this.vModel.contents
      send.category = this.response.contents.category
      send.type = this.vModel.type
      this.xhttp({
        url: 'contents/' + this.response.partner + '/update/' + this.$route.params.contents_id,
        method: 'put',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.edit = response.data.data
          this.snack('success', '수정 완료되었습니다.')
          this.beforePage()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 썸네일 등록시 저장 */
    thumbnailFile () {
      let formData = new FormData()
      formData.append('upload', this.thumbnail_url)
      this.xhttp({
        url: '/contents/file-upload',
        method: 'post',
        data: formData
      }).then((response) => {
        if (response.status === 200) {
          this.vModel.data.thumbnail_url = response.data.link
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
  },
  computed: {
    resultType () {
      if (this.response.routeName === 'contentsEdit') {
        return '수정'
      } else {
        return '등록'
      }
    }
  },
  watch: {
    /* 썸네일 등록시 */
    thumbnail_url: function (val) {
      if (val) {
        this.thumbnailFile()
      }
    }
  }
}
</script>

<style lang="scss">
    .v-application p {
        margin-bottom: 10px;
    }
    #editor{
       min-height:400px
     }
</style>
