export const editorSet = {
  methods: {
    /* 이미지 추가 했을 때 */
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      let formData = new FormData()
      formData.append('upload', file)
      this.xhttp({
        url: '/contents/file-upload',
        method: 'post',
        data: formData
      }).then((response) => {
        if (response.status === 200) {
          let src = response.data.link
          Editor.insertEmbed(cursorLocation, 'image', src)
          let div = document.createElement('div')
          div.innerHTML = document.querySelector('.ql-editor').innerHTML
          let img = div.querySelectorAll('img')
          for (let i = 0; i < img.length; i++) {
            /* 이미지 중에 지금 추가한 이미지 */
            if (img[i].src === 'http:' + src) {
              let dd = div.querySelectorAll('img')[i].parentNode.innerHTML
              dd += '<h5 class="figcaption">Image Caption</h5>'
              div.querySelectorAll('img')[i].parentNode.innerHTML = dd
              document.querySelector('.ql-editor').innerHTML = div.querySelectorAll('img')[i].parentNode.parentNode.innerHTML
            }
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 에디터에 붙여넣기 했을 때 태그 변경 */
    editorChange () {
      let div = document.createElement('div')
      div.innerHTML = document.querySelector('.ql-editor').innerHTML.replace(/<(\/strong|strong)([^>]*)>/gi, '').replace(/<(\/span|span)([^>]*)>/gi, '')
      let img = div.querySelectorAll('img')
      for (let i = 0; i < img.length; i++) {
        /* 이미지에 캡션 있을 때 */
        if (img[i].parentNode.innerText) {
          let figcaption = img[i].parentNode.innerText
          if (figcaption) {
            let div2 = document.createElement('div')
            div2.innerHTML = img[i].outerHTML + '<h5 class="figcaption">' + figcaption + '</h5>'
            let dd = div2.innerHTML
            div.querySelectorAll('img')[i].parentNode.innerHTML = dd
            if (div.querySelectorAll('img')[i]) {
              document.querySelector('.ql-editor').innerHTML = div.querySelectorAll('img')[i].parentNode.parentNode.innerHTML
            }
          }
        }
      }
    },
    /* 저장되어있던 내용 에디터에 넣을때 태그 변환 */
    editorSaveTagChange (val) {
      let text = this.convertHtml(val)
      let div = document.createElement('div')
      div.innerHTML = text
      let img = div.querySelectorAll('img')
      for (let i = 0; i < img.length; i++) {
        if (img[i].parentNode.getAttribute('href')) {
          /* 링크 걸린 이미지 */
          let imgHtml = img[i].parentNode.parentNode.outerHTML
          let figure = imgHtml.replace(/(<figure)(.+?)(<\s?\/\s?figure>)/, '<p$2</p>')
          div.querySelectorAll('img')[i].parentNode.parentNode.outerHTML = figure
        } else {
          /* 링크 없는 이미지 */
          let imgHtml = img[i].parentNode.outerHTML
          let figure = imgHtml.replace(/(<figure)(.+?)(<\s?\/\s?figure>)/, '<p$2</p>')
          div.querySelectorAll('img')[i].parentNode.outerHTML = figure
        }
      }
      let h5 = div.querySelectorAll('figcaption')
      for (let i = 0; i < h5.length; i++) {
        div.querySelectorAll('figcaption')[0].outerHTML = div.querySelectorAll('figcaption')[0].outerHTML.replace(/(<figcaption)(.+?)(<\s?\/\s?figcaption>)/, '<h5 class="figcaption"$2</h5>')
      }
      return div.innerHTML
    },
    /* 에디터 내용 저장할때 태그 변환 */
    editorTagChange (val) {
      let text = this.vModel.data.contents
      let div = document.createElement('div')
      div.innerHTML = text
      let img = div.querySelectorAll('img')
      let h5 = div.querySelectorAll('h5')
      let h5Arr = []
      /* h5태그에 있는 텍스트를 차례대로 arr에 저장 */
      for (let j = 0; j < h5.length; j++) {
        let h5Class = h5[j].getAttribute('class')
        if (h5Class.indexOf('figcaption') !== -1) {
          h5Arr.push(h5[j].innerText)
        }
      }
      let textIdx = 0
      for (let i = 0; i < img.length; i++) {
        let qlContents = document.querySelector('.ql-editor')
        let imgTop = qlContents.querySelectorAll('img')[i].offsetTop + qlContents.querySelectorAll('img')[i].height
        let textTop = qlContents.querySelectorAll('h5')[textIdx].offsetTop
        /* 링크 걸린 이미지 */
        if (img[i].parentNode.getAttribute('href')) {
          let imgHtml = img[i].parentNode.parentNode.outerHTML
          let figure = imgHtml.replace(/(<p)(.+?)(<\s?\/\s?p>)/, '<figure$2</figure>')
          div.querySelectorAll('img')[i].parentNode.parentNode.outerHTML = figure
          let contents = div.querySelectorAll('img')[i].parentNode.parentNode.innerHTML
          /* h5 있으면 */
          if (h5Arr.length > 0) {
            /* 이미지 뒤에 추가 */
            if (textTop < imgTop + 10) {
              contents += '<figcaption>' + h5Arr[textIdx] + '</figcaption>'
              h5[textIdx].remove()
              textIdx++
            }
          }
          div.querySelectorAll('img')[i].parentNode.parentNode.innerHTML = contents
        } else {
          /* 링크 없는 이미지 */
          let imgHtml = img[i].parentNode.outerHTML
          let figure = imgHtml.replace(/(<p)(.+?)(<\s?\/\s?p>)/, '<figure$2</figure>')
          div.querySelectorAll('img')[i].parentNode.outerHTML = figure
          let contents = div.querySelectorAll('img')[i].parentNode.innerHTML
          /* h5 있으면 */
          if (h5Arr.length > 0) {
            /* 이미지 뒤에 추가 */
            if (textTop < imgTop + 10) {
              contents += '<figcaption>' + h5Arr[textIdx] + '</figcaption>'
              h5[textIdx].remove()
              textIdx++
            }
          }
          div.querySelectorAll('img')[i].parentNode.innerHTML = contents
        }
      }
      return div.innerHTML
    }
  }
}
